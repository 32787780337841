import { SyntheticEvent, useCallback } from 'react'
import styled from 'styled-components'
import { useHotkeys } from 'react-hotkeys-hook'

import { Box, Button, Checkbox, IconButton, Text, Tooltip, useNotify } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookViewModel, TaskModel } from 'main/data-access'

export const TaskListHeader = ({ canCreateRootTask }: { canCreateRootTask: boolean }) => {
  const { t } = useLanguage('tasks')
  const notify = useNotify()

  const { can: canBulkEdit } = RunbookViewModel.usePermission('initiate:bulkEditActions')
  const getBulkEditPermission = RunbookViewModel.usePermissionCallback('initiate:bulkEditActions')
  const { can: canBulkProgress } = RunbookViewModel.usePermission('initiate:bulkProgressionActions')
  const openModal = RunbookViewModel.useAction('modal:open')
  const selectedIds = RunbookViewModel.useGet('selectedIds')
  const getSelectedIds = RunbookViewModel.useGetCallback('selectedIds')
  const toggleAllSelectedIds = RunbookViewModel.useAction('selectedIds:toggleAll')
  const copyIdsAdd = RunbookViewModel.useAction('copyIds:add')

  const handleSkipTasks = TaskModel.useAction('bulk_skip')

  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  const toggleTaskCreate = RunbookViewModel.useAction('taskCreate:toggle')
  const { predecessor: fromPredecessorId } = RunbookViewModel.useGet('taskCreate')

  const disableCreateButton = fromPredecessorId === 0
  const isSelectMode = !!selectedIds.length
  const allTasksSelected = selectedIds.length === filteredTaskIds.length

  const handleSkip = useCallback(() => {
    handleSkipTasks(selectedIds)
  }, [selectedIds, handleSkipTasks])

  useHotkeys('meta+c', () => handleCopyTasks(), { preventDefault: true }, [])

  const handleChangeSelectAll = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      toggleAllSelectedIds()
    },
    [toggleAllSelectedIds]
  )

  const handleClickCreateTask = () => {
    toggleTaskCreate({ predecessor: 0 })
  }

  const handleCopyTasks = async () => {
    const bulkEditPermissions = await getBulkEditPermission()

    if (bulkEditPermissions.error === 'NO_SELECTED_IDS')
      notify.warning(t('clipboard.noTasksSelected'), { title: t('clipboard.noTasksSelectedTitle') })

    if (bulkEditPermissions.can) {
      const selectedIds = await getSelectedIds()
      copyIdsAdd(selectedIds)
      notify.success(t('clipboard.copy', { count: selectedIds.length }), {
        title: t('clipboard.copyTitle', { count: selectedIds.length })
      })
    } else {
      notify.warning(t('clipboard.unableToCopy'), { title: t('clipboard.unableToCopyTitle') })
    }
  }

  return (
    <Box direction="row" align="center" gap="medium" height={{ min: '64px' }}>
      <Box direction="row" css="min-width: 140px;">
        <Box
          align="center"
          justify="center"
          height="40px"
          width={{ width: '40px', min: '40px' }}
          margin={{ left: '6px' }}
        >
          <Tooltip
            placement="top"
            content={isSelectMode ? t('bulkActionTooltip.deselect') : t('bulkActionTooltip.select')}
          >
            <Checkbox
              data-testid="task-select-all"
              width={16}
              disabled={filteredTaskIds.length === 0}
              checked={isSelectMode && allTasksSelected}
              indeterminate={isSelectMode && !allTasksSelected}
              onChange={handleChangeSelectAll}
              onClick={e => e.stopPropagation()}
            />
          </Tooltip>
        </Box>
        {!(canBulkEdit || canBulkProgress) && (
          <Text
            alignSelf="center"
            data-testid="task-select-all-label"
            color={filteredTaskIds.length === 0 ? 'text-disabled' : 'text-light'}
          >
            {t('list.header.checkboxLabel')}
          </Text>
        )}

        <Box direction="row" gap="xxsmall">
          {canBulkProgress && (
            <IconButton
              tertiary
              label={t('bulkActionTooltip.skip')}
              data-testid="bulk-skip-task-button"
              icon="skip"
              onClick={handleSkip}
              tipPlacement="top"
            />
          )}
          {canBulkEdit && (
            <>
              <IconButton
                tertiary
                label={t('bulkActionTooltip.copy')}
                icon="copy"
                tipPlacement="top"
                onClick={handleCopyTasks}
              />
              <IconButton
                tertiary
                label={t('bulkActionTooltip.delete')}
                icon="trash-o"
                tipPlacement="top"
                onClick={() => openModal({ type: 'tasks-delete', id: selectedIds })}
              />
            </>
          )}
          {(canBulkEdit || canBulkProgress) && (
            <SingleLineText data-testid="task-select-all-label">{`${selectedIds.length} ${t(
              'list.header.selected'
            )}`}</SingleLineText>
          )}
        </Box>
      </Box>

      {canCreateRootTask && !isSelectMode && (
        <Box>
          <Button
            data-testid="create-task-no-predecessors-button"
            tertiary
            disableTooltip
            disabled={disableCreateButton}
            icon="add"
            onClick={handleClickCreateTask}
            label={t('createTask')}
          />
        </Box>
      )}
    </Box>
  )
}

const SingleLineText = styled(Text).attrs(() => ({
  alignSelf: 'center',
  color: 'text-light'
}))`
  overflow: hidden;
  white-space: nowrap;
`
