import { useState } from 'react'
import { eventManager } from 'event-manager'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Box, Message, RunbookTypeIcon } from '@cutover/react-ui'
import { DuplicateRunbookForm } from './duplicate-runbook-form/duplicate-runbook-form'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { FormModal } from 'main/components/shared/form'
import { useLanguage } from 'main/services/hooks'
import {
  DuplicateRunbookResponseType,
  useBulkDuplicateRunbooks,
  useDuplicateRunbook
} from 'main/services/queries/use-duplicate-runbooks'
import { useRouting } from 'main/services/routing'
import {
  CustomField,
  CustomFieldGroup,
  CustomFieldUser,
  RunbookListRunbook,
  RunbookShowRunbook,
  RunbookTypeType
} from 'main/services/queries/types'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { ConfigModel } from 'main/data-access'
import { FormRenderProps } from '../form/form'

const formType = createValidationSchema(yup.object())
export type DuplicateRunbookFormSchema = yup.InferType<typeof formType>

type DuplicateRunbooksProps = {
  runbook?: RunbookListRunbook | RunbookShowRunbook
  runbookIds?: number[] | null
  runbookType?: RunbookTypeType
  reloadRunbookList?: () => void
  customFieldProps?: {
    customFieldsLookup?: Record<number, CustomField>
    customFieldUsers?: CustomFieldUser[]
    customFieldGroupsLookup?: Record<number, CustomFieldGroup>
    customFieldGroups?: CustomFieldGroup[]
  }
  hasPermissionOnExistingProject?: boolean
  projects?: PermittedProject[]
  open?: boolean
  loading?: boolean
  closeModal?: () => void
  errorMessage?: string[]
  warningMessage?: string[]
  context: 'single' | 'bulk-single' | 'bulk'
  templateType?: 'default' | 'snippet'
  // data passed from angular to duplicate a snippet
  snippetFromAngular?: { id: number; name: string } | null
}

export const DuplicateRunbooksModal = ({
  closeModal,
  context,
  customFieldProps,
  errorMessage,
  hasPermissionOnExistingProject,
  open,
  projects,
  loading: loadingProp,
  reloadRunbookList,
  runbook,
  runbookIds,
  runbookType,
  snippetFromAngular,
  templateType,
  warningMessage
}: DuplicateRunbooksProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'duplicateRunbookModal' })
  const navigate = useNavigate()
  const isReactWorkspaceEnabled = ConfigModel.useIsFeatureEnabled('react_workspace')
  const { toRunbook } = useRouting()
  const runbookTypeContext = !templateType ? 'runbook' : templateType === 'default' ? 'template' : 'snippet'
  const isSnippet = runbookTypeContext === 'snippet'

  const [loading, setLoading] = useState(!isSnippet)

  const { mutateAsync: bulkMutation } = useBulkDuplicateRunbooks(runbookIds || [])
  const { mutateAsync: singleMutation } = useDuplicateRunbook(runbook?.id ?? snippetFromAngular?.id)

  const {
    initialFieldValues,
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_add_edit'],
    alwaysNotRequired: context === 'bulk' || isSnippet,
    customFieldsLookup: customFieldProps?.customFieldsLookup,
    fieldValues: runbook?.field_values,
    customFieldGroups: customFieldProps?.customFieldGroups,
    constraintContext: { runbook_type_id: runbook?.runbook_type_id },
    excludeTransientFieldValues: true
  })

  const defaultValues: Partial<DuplicateRunbookFormSchema> = {
    _step: 1,
    context: runbookTypeContext,
    bulk: context !== 'single',
    single: context !== 'bulk',
    project_id: runbook?.project_id,
    name: context === 'single' ? `${runbook?.name ?? snippetFromAngular?.name} copy` : undefined,
    suffix: context !== 'single' ? 'copy' : undefined,
    copy_tasks: true,
    copy_teams: true,
    copy_users: true,
    project_modify_type: !isSnippet ? (hasPermissionOnExistingProject ? 'existing' : 'choose') : undefined,
    shift_time: !isSnippet ? true : undefined,
    timing_mode: !isSnippet ? 'unscheduled' : undefined,
    field_values: !isSnippet ? buildDefaultFieldValues(customFields, groupedCustomFields, initialFieldValues) : {}
  }

  const handleSuccess = (data?: DuplicateRunbookResponseType) => {
    if (context !== 'single') {
      isReactWorkspaceEnabled ? reloadRunbookList?.() : eventManager.emit('reload-runbook-list-on-bulk-duplicate')
      closeModal?.()
    } else {
      if (data) {
        const runbook: DuplicateRunbookResponseType = data
        if (runbook) {
          // Redirect to task list
          navigate(toRunbook({ accountSlug: runbook.account_slug, runbookId: runbook.id }))
        }
      }
    }
  }

  const transformer = (data: DuplicateRunbookFormSchema) => {
    const isProjectChecked = data.project_modify_type === 'choose' || data.single
    const isTimingModePlanned = data.timing_mode === 'unscheduled'

    if (context !== 'single') {
      return {
        options: {
          suffix: data.suffix,
          copy_tasks: data.copy_tasks,
          copy_teams: data.copy_teams,
          copy_users: data.copy_users,
          is_template: !isSnippet ? false : undefined,
          project_id: isProjectChecked ? data.project_id : undefined,
          shift_time: !isSnippet ? data.shift_time : undefined,
          start_planned: isTimingModePlanned ? data.start_planned : null,
          start_scheduled: !isTimingModePlanned ? data.start_scheduled : null,
          end_scheduled: !isTimingModePlanned ? data.end_scheduled : null,
          template_type: isSnippet ? 'snippet' : undefined,
          template_status: isSnippet ? 'template_draft' : undefined
        },
        runbook: {
          field_values_attributes:
            context === 'bulk-single'
              ? data.field_values && buildFieldValuesAttributesRequestData(data.field_values, true)
              : []
        },
        runbook_ids: runbookIds
      }
    } else {
      return {
        name: data.name,
        copy_tasks: data.copy_tasks,
        copy_teams: data.copy_teams,
        copy_users: data.copy_users,
        template_type: isSnippet ? 'snippet' : undefined,
        template_status: isSnippet ? 'template_draft' : undefined,
        shift_time: data.shift_time,
        project_id: isProjectChecked ? data.project_id : undefined,
        runbook_versions_attributes: [
          {
            start_planned: isTimingModePlanned ? data.start_planned : null,
            start_scheduled: !isTimingModePlanned ? data.start_scheduled : null,
            end_scheduled: !isTimingModePlanned ? data.end_scheduled : null
          }
        ],
        field_values_attributes: data.field_values && buildFieldValuesAttributesRequestData(data.field_values, true)
      }
    }
  }

  // Transformer returns correct type for each endpoint
  const handleSubmit = (data: ReturnType<typeof transformer>) => {
    if (context !== 'single') {
      return bulkMutation(data as any)
    } else {
      return singleMutation(data as any)
    }
  }

  const confirmText = (formContext: FormRenderProps<any>) => {
    const step = formContext.watch('_step')
    if (step === 1 && !isSnippet && !runbookType?.incident) {
      return t('common:nextButton')
    } else {
      return t('common:createButton')
    }
  }

  const title = (formContext: FormRenderProps<any>) => {
    const step = formContext.watch('_step')
    return step === 1
      ? context !== 'bulk' && runbookTypeContext === 'template'
        ? t('createRunbookFromTemplate')
        : t('duplicate', { count: runbookIds?.length ?? 1, context: runbookTypeContext })
      : t('secondStepTitle')
  }

  const successMessage =
    context !== 'single'
      ? t('notification.success.message', { count: runbookIds?.length ?? 1, context: runbookTypeContext })
      : undefined

  return (
    <FormModal<DuplicateRunbookFormSchema, ReturnType<typeof transformer>>
      schema={createValidationSchema(fieldValueValidation)}
      title={title}
      titleIcon={
        runbookType && (
          <RunbookTypeIcon
            icon={runbookType.icon_name || 'getting-started'}
            color={runbookType.icon_color || 'primary'}
          />
        )
      }
      confirmText={confirmText}
      confirmIcon={'add'}
      steps={isSnippet || runbookType?.incident ? 1 : 2}
      open={open}
      onClose={closeModal}
      onSuccess={handleSuccess}
      defaultValues={defaultValues}
      transformer={transformer}
      onSubmit={handleSubmit}
      successMessage={successMessage}
      loading={loading || loadingProp}
      confirmDisabled={!!errorMessage?.length}
      customErrors={errorMessage}
    >
      {warningMessage && (
        <Box margin={{ bottom: '16px' }}>
          <Message role="alert" type="warning" message={warningMessage} />
        </Box>
      )}
      {!loadingProp && (
        <DuplicateRunbookForm
          context={runbookTypeContext}
          isBulkDuplicate={context !== 'single'}
          isSnippet={isSnippet}
          isSingleRunbook={context !== 'bulk'}
          customFields={customFields}
          groupedCustomFields={groupedCustomFields}
          customFieldGroupsLookup={customFieldProps?.customFieldGroupsLookup}
          displayCustomFields={!isSnippet && !!runbook}
          customFieldUsers={customFieldProps?.customFieldUsers}
          hasPermissionOnExistingProject={hasPermissionOnExistingProject}
          projects={projects}
          runbook={runbook}
          setLoading={setLoading}
        />
      )}
    </FormModal>
  )
}

function createValidationSchema(fieldValuesValidation: any) {
  return yup.object({
    // these fields are not exposed for edit or sent to backend, used for conditional validation of other fields
    _step: yup.number().oneOf([1, 2]),
    context: yup.string().oneOf(['runbook', 'template', 'snippet']),
    bulk: yup.boolean(),
    single: yup.boolean(),

    // step 1
    name: yup.string().when('bulk', {
      is: false,
      then: schema => schema.required()
    }),
    suffix: yup.string().nullable(),
    project_modify_type: yup
      .string()
      .oneOf(['choose', 'existing'])
      .when(['context', 'single'], {
        is: (context: 'runbook' | 'template' | 'snippet', single: boolean) => context !== 'snippet' && !single,
        then: schema => schema.required()
      }),
    project_id: yup
      .number()
      .nullable()
      .when(['project_modify_type', 'single', 'context'], {
        is: (
          project_modify_type: 'choose' | 'existing',
          single: boolean,
          context: 'runbook' | 'template' | 'snippet'
        ) => {
          return context !== 'snippet' && (single || project_modify_type === 'choose')
        },
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      }),
    copy_tasks: yup.boolean(),
    copy_teams: yup.boolean(),
    copy_users: yup.boolean(),
    field_values: fieldValuesValidation,

    // step 2
    timing_mode: yup
      .string()
      .oneOf(['scheduled', 'unscheduled'])
      .when(['_step', 'context'], {
        is: (_step: number, context: 'runbook' | 'template' | 'snippet') => _step === 2 && context !== 'snippet',
        then: schema => schema.required(),
        otherwise: schema => schema.notRequired()
      }),

    start_scheduled: yup.date().when(['_step', 'timing_mode'], {
      is: (_step: number, timing_mode: 'scheduled' | 'unscheduled') => _step === 2 && timing_mode === 'scheduled',
      then: schema => schema.required(),
      otherwise: schema => schema.notRequired()
    }),
    start_planned: yup.date(),
    end_scheduled: yup.date().notRequired(),
    shift_time: yup.boolean()
  })
}
