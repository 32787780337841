import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Box, CardGroup, CardGroupOption, FeatureIcon } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import {
  CustomFieldsGroupsForm,
  CustomFieldsGroupsFormProps
} from 'main/components/shared/custom-field-form/custom-field-groups-form'
import {
  CheckboxFieldControlled,
  DateTimePickerField,
  FolderSelectField,
  RunbookSelectField,
  TextInputField
} from 'main/components/shared/form'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { CreateRunbookFormType } from './create-runbook-schema'

type CreateRunbookDetailsProps = CustomFieldsGroupsFormProps & {
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
}

export const CreateRunbookDetails = ({
  isTemplate = false,
  folders,
  ...customFieldsProps
}: CreateRunbookDetailsProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal' })
  const { watch, getValues, setValue, setFocus } = useFormContext<CreateRunbookFormType>()

  const { accountId: accountSlug } = useParams()

  const [isCreateFromTemplate, setIsCreateFromTemplate] = useState<boolean>(false)
  const [isStartScheduled, setIsStartScheduled] = useState<boolean>(false)

  // do i18n below
  const cardOptions: CardGroupOption[] = [
    {
      title: 'Blank runbook',
      subtitle: 'Create a blank runbook',
      startComponent: <FeatureIcon status={isCreateFromTemplate ? 'info' : 'idea'} icon="runbook-created" />,
      active: !isCreateFromTemplate,
      key: 1
    },
    {
      title: 'Use template',
      subtitle: 'Select a template to start from',
      startComponent: <FeatureIcon status={isCreateFromTemplate ? 'idea' : 'info'} icon="template" />,
      active: isCreateFromTemplate,
      key: 2
    }
  ]

  useEffect(() => {
    const baseTemplate = getValues('base_template')
    const projectId = getValues('base_template')?.project?.id

    setValue('runbook.name', baseTemplate ? `${baseTemplate.name} copy` : '')
    setFocus('runbook.name')

    projectId ? setValue('runbook.project_id', projectId) : undefined
  }, [watch('base_template')])

  useEffect(() => {
    if (!!getValues('runbook.runbook_versions_attributes.0.start_scheduled')) {
      setIsStartScheduled(true)
    } else {
      setIsStartScheduled(false)
    }
  }, [watch('runbook.runbook_versions_attributes.0.start_scheduled')])

  useEffect(() => {
    const defaultFolder = folders?.find(folder => folder.name === 'Default')
    setValue('runbook.project_id', defaultFolder ? defaultFolder.id : folders?.[0]?.id)
  }, [folders])

  return (
    <Box>
      <CardGroup onSelect={index => setIsCreateFromTemplate(index === 2)} options={cardOptions} />

      {isCreateFromTemplate && accountSlug && (
        <RunbookSelectField<CreateRunbookFormType>
          name="base_template"
          label={t('enterDetails.attributes.template.label')}
          accountSlug={accountSlug}
          required={isCreateFromTemplate}
          params={{
            // TODO: for X runbook type only
            limit: 10,
            a: true,
            template_type: 'default',
            fields: ['id', 'name', 'project', 'approval_status']
          }}
          placeholder={'Base template'}
        />
      )}

      <TextInputField<CreateRunbookFormType>
        name="runbook.name"
        required
        label={t('enterDetails.attributes.name')}
        data-testid="runbook-name-form-field"
      />

      <FolderSelectField<CreateRunbookFormType>
        label={t('enterDetails.attributes.folderSelect')}
        folders={folders ?? []}
        name="runbook.project_id"
        clearable={false}
        loading={folders === undefined}
      />

      {!isTemplate && (
        <>
          <DateTimePickerField<CreateRunbookFormType>
            fixed
            fixedWidth
            name="runbook.runbook_versions_attributes.0.start_scheduled"
            label={t('enterDetails.attributes.startScheduled')}
          />

          {isStartScheduled && (
            <>
              <DateTimePickerField<CreateRunbookFormType>
                fixed
                fixedWidth
                name="runbook.runbook_versions_attributes.0.end_scheduled"
                label={t('enterDetails.attributes.endScheduled')}
              />

              {isCreateFromTemplate && (
                <CheckboxFieldControlled<CreateRunbookFormType>
                  name="shift_time"
                  label={t('enterDetails.attributes.shiftTime.label')}
                  helpText={t('enterDetails.attributes.shiftTime.helpText')}
                />
              )}
            </>
          )}
        </>
      )}

      {/* TODO add errors={errors} prop and feed through? */}
      <CustomFieldsGroupsForm {...customFieldsProps} />
    </Box>
  )
}
