import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

import { CardGroup, CardGroupOption, RunbookTypeIcon } from '@cutover/react-ui'
import { useLanguage, useWorkspacePermittedProjects } from 'main/services/hooks'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers,
  useAccountRunbookTypes
} from 'main/services/api/data-providers/account/account-data'
import { FormModal } from 'main/components/shared/form'
import { useCustomFieldForm } from 'main/components/shared/custom-field-form'
import { RunbookCreatePayload, useRunbooksCreate } from 'main/services/queries/use-runbook'
import { buildCreateRunbookSchema, CreateRunbookFormType } from './create-runbook-schema'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { getClientIANATimezone } from 'main/services/timezone-util'
import { PermittedProject } from 'main/services/queries/use-permitted-resources'
import { CustomField, CustomFieldGroup, CustomFieldUser, RunbookTypeType } from 'main/services/queries/types'
import { CreateRunbookDetails } from './create-runbook-details'
import { FormRenderProps } from 'main/components/shared/form/form'

type CreateRunbookModalProps = {
  open: boolean
  onClickClose: () => void
  isTemplate?: boolean
}

export const CreateRunbookModal = ({ open, onClickClose, isTemplate = false }: CreateRunbookModalProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'newCreateRunbookModal' })
  const { account } = useAccount()
  const accountId = account?.id
  const timezone = getClientIANATimezone()
  const { runbookTypes } = useAccountRunbookTypes()

  const { customFieldsLookup } = useAccountCustomFields()
  const { projects: folders } = useWorkspacePermittedProjects({ accountId })
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const { customFieldUsers } = useAccountCustomFieldUsers()

  const createMutation = useRunbooksCreate()
  const navigate = useNavigate()

  const {
    fieldValueValidation,
    buildFieldValuesAttributesRequestData,
    data: { customFields, groupedCustomFields }
  } = useCustomFieldForm({
    applyToSlugs: ['runbook_add_edit'],
    customFieldsLookup,
    customFieldGroups
  })

  const customFieldProps = {
    customFields,
    customFieldUsers,
    groupedCustomFields,
    customFieldGroupsLookup
  }

  const defaultValues = {
    _step: 1,
    create_from_template: 'false',
    runbook: {
      name: '',
      account_id: accountId,
      runbook_type_id: 1,
      is_template: isTemplate,
      timezone: timezone,
      roles: []
    },
    shift_time: false,
    field_values: buildDefaultFieldValues(customFields, groupedCustomFields)
  } as unknown as CreateRunbookFormType

  const resolveTitle = (formContext: FormRenderProps<any>) => {
    const currentStep = formContext.watch('_step')
    if (currentStep === 1) {
      return isTemplate ? t('chooseRunbookType.templateHeading') : t('chooseRunbookType.runbookHeading')
    } else {
      const runbookTypeId = formContext.watch('runbook.runbook_type_id')
      const runbookTypeName = runbookTypes?.find(type => type.id === runbookTypeId)?.name
      return t('enterDetails.heading', { runbookTypeName: runbookTypeName })
    }
  }

  const dataTransformer = ({ field_values, base_template, runbook, role_types, shift_time }: CreateRunbookFormType) => {
    return {
      base_runbook_id: base_template?.id,
      runbook: {
        ...runbook,
        field_values_attributes: buildFieldValuesAttributesRequestData(field_values),
        roles:
          role_types
            ?.map(role =>
              (role.users || []).map(user => ({
                role_type_id: role.id,
                subject_id: user.id,
                subject_type: 'User'
              }))
            )
            .flat() ?? []
      },
      shift_time: shift_time
    }
  }

  return (
    <>
      <FormModal<CreateRunbookFormType, RunbookCreatePayload>
        width={650}
        schema={buildCreateRunbookSchema(fieldValueValidation)}
        title={resolveTitle}
        confirmText={t('formSubmission.submitButtonText')}
        steps={2}
        open={open}
        onClose={onClickClose}
        onSubmit={createMutation.mutateAsync}
        defaultValues={defaultValues}
        successMessage={isTemplate ? t('formSubmission.templateSuccess') : t('formSubmission.runbookSuccess')}
        onSuccess={data =>
          navigate(`/app/${account?.slug}/runbooks/${data?.runbook?.id}/current_version/tasks/react-list`)
        }
        onError={isTemplate ? t('formSubmission.templateError') : t('formSubmission.runbookError')}
        transformer={dataTransformer}
        hideFooter={formContext => {
          return formContext.watch('_step') === 1
        }}
      >
        <CreateRunbookModalContent
          runbookTypes={runbookTypes?.sort((a, b) => a.id - b.id)}
          isTemplate={isTemplate}
          folders={folders}
          customFieldsProps={customFieldProps}
        />
      </FormModal>
    </>
  )
}

type CreateRunbookModalContentProps = {
  folders: PermittedProject[] | undefined
  isTemplate?: boolean
  customFieldsProps: {
    customFields?: CustomField[]
    customFieldUsers?: CustomFieldUser[]
    groupedCustomFields?: Record<number, CustomField[]>
    customFieldGroupsLookup?: Record<number, CustomFieldGroup>
  }
  runbookTypes: RunbookTypeType[] | undefined
}

const CreateRunbookModalContent = ({
  isTemplate = false,
  folders,
  customFieldsProps,
  runbookTypes
}: CreateRunbookModalContentProps) => {
  const { watch, setValue } = useFormContext<CreateRunbookFormType>()
  const currentStep = watch('_step')

  const handleSelectRunbookType = (selectedTypeId: number) => {
    setValue('_step', 2)
    setValue('runbook.runbook_type_id', selectedTypeId)
  }

  const runbookTypeOptions: CardGroupOption[] = (runbookTypes ?? []).map(type => ({
    key: type.id,
    title: type.name,
    subtitle: type.description,
    startComponent: <RunbookTypeIcon icon={type.icon_name} color={type.icon_color} />
  }))

  switch (currentStep) {
    case 1:
      return (
        <CardGroup
          onSelect={handleSelectRunbookType}
          options={runbookTypeOptions}
          data-testid="select-runbook-types-grid"
        />
      )
    case 2:
      return <CreateRunbookDetails isTemplate={isTemplate} folders={folders} {...customFieldsProps} />
    default:
      return null
  }
}
